export enum SUBDOMAINS {
    AUTH = 'auth',
    MAC_ADDRESS = 'mac-address',
    THREAT_INTELLIGENCE = 'threat-intelligence'
}

export enum PATHS {
    HOME = '/',
    ABOUT_US = '/about-us',
    DOCUMENTATION = '/documentation',
    FAQ = '/faq',
    LOGIN = '/login',
    REGISTER = '/register',
    CONTACT_SALES = '/contact-sales',
    TERMS_OF_SERVICE = '/terms-of-service',
    PRIVACY_POLICY = '/privacy-policy',
    MAC_ADDRESS_GENERATOR = '/mac-address-generator',
    MAC_ADDRESS = '/macaddress',
    MAC_ADDRESS_API = '/mac-address-api',
    MAC_ADDRESS_API_DOCUMENTATION = '/mac-address-api/documentation',
    MAC_ADDRESS_API_LIBRARIES = '/mac-address-api/libraries',
    MAC_ADDRESS_API_INTEGRATIONS = '/mac-address-api/integrations',
    MAC_ADDRESS_API_UTILITIES = '/mac-address-api/utilities',
    MAC_ADDRESS_LOOKUP = '/mac-address-lookup',
    STATISTICS = '/statistics',
    THREAT_STATISTICS = '/threat-statistics',
    THREAT_LOOKUP = '/threat-lookup',
    THREAT_API = '/threat-api',
    THREAT_API_DOCUMENTATION = '/threat-api/documentation',
    STATISTICS_BY_COUNTRY = '/statistics/country',
    STATISTICS_BY_COMPANY = '/statistics/company',
    STATISTICS_BY_BLOCK_SIZE = '/statistics/block-size',
    STATISTICS_BY_DATE = '/statistics/date'
}

export const FADE_IN_ANIMATION_SETTINGS = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.2 }
};

export const FADE_IN_ANIMATION_VARIANTS = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { type: 'spring' } }
};

export const FADE_DOWN_ANIMATION_VARIANTS = {
    hidden: { opacity: 0, y: -10 },
    show: { opacity: 1, y: 0, transition: { type: 'spring' } }
};

export const FADE_UP_ANIMATION_VARIANTS = {
    hidden: { opacity: 0, y: 10 },
    show: { opacity: 1, y: 0, transition: { type: 'spring' } }
};

export const MAC_ADDRESS_JSON_SAMPLE = `{
    "oui": "08:EC:F5",
    "isPrivate": false,
    "companyName": "Cisco Systems, Inc",
    "companyAddress": "80 West Tasman Drive San Jose CA 94568",
    "countryCode": "US",
    "assignmentBlockSize": "MA-L",
    "dateCreated": "2018-11-09",
    "dateUpdated": "2018-11-09"
}`;

export const MAC_ADDRESS_XML_SAMPLE = `<record>
    <oui>08:EC:F5</oui>
    <isPrivate>0</isPrivate>
    <companyName>Cisco Systems, Inc</companyName>
    <companyAddress>80 West Tasman Drive San Jose CA 94568</companyAddress>
    <countryCode>US</countryCode>
    <assignmentBlockSize>MA-L</assignmentBlockSize>
    <dateCreated>2018-11-09</dateCreated>
    <dateUpdated>2018-11-09</dateUpdated>
</record>`;

export const safeDocument: Document | undefined =
    typeof document === 'undefined' ? undefined : document;
