import dateFormat from 'dateformat';

export function dateToString(
    date: Date | number | string,
    format: string = 'mmmm d, yyyy'
): string {
    return dateFormat(date, format);
}

export function timeAgo(date: Date | string | number): string {
    date = new Date(date);
    const currentDate = new Date();
    const timestamp = date.getTime();
    const currentTimestamp = currentDate.getTime();
    const timeDifference = currentTimestamp - timestamp;

    const secondsInADay = 24 * 60 * 60 * 1000;
    const secondsInAWeek = 7 * 24 * 60 * 60 * 1000;

    if (timeDifference < secondsInADay) {
        // Less than a day
        return 'Today';
    } else if (timeDifference < secondsInAWeek) {
        // Less than a week
        const days = Math.floor(timeDifference / 86400000);
        return `${days} day${days !== 1 ? 's' : ''} ago`;
    }

    return dateToString(date);
}
