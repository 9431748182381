import { GoogleAnalytics } from 'nextjs-google-analytics';

const HeadAnalytics = () => {
    return (
        <>
            <GoogleAnalytics trackPageViews />
        </>
    );
};

export default HeadAnalytics;
