import type { AppProps } from 'next/app';
import type { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import '@/styles/globals.css';
import CookieBanner from '@/widgets/CookieBanner/ui/CookieBanner';
import { Toaster } from 'react-hot-toast';
import { HeadAnalytics } from '@/shared/ui/HeadAnalytics';

export default function App({ Component, pageProps }: AppProps) {
    const { session }: { session: Session } = pageProps;

    return (
        <SessionProvider session={session}>
            <HeadAnalytics />
            <Component {...pageProps} />
            <CookieBanner />
            <Toaster />
        </SessionProvider>
    );
}
