import { Button, ButtonTheme } from '@/shared/ui/Button';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { composeLink } from '@/shared/lib/links';
import { PATHS } from '@/shared/lib/constants';

interface CookieBannerProps {
    className?: string;
}

const cookieName = 'adf_cookie';

const CookieBanner = (props: CookieBannerProps) => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const cookie = getFromCookie(cookieName);
        if (cookie) {
            setShowBanner(false);
        } else {
            setShowBanner(true);
        }
    }, []);

    function handleAccept() {
        setCookie(cookieName, 'true', 365);
        setShowBanner(false);
    }

    function getFromCookie(name: string) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop()?.split(';').shift();
        }
    }

    function setCookie(name: string, value: string, days: number) {
        const expires = new Date(Date.now() + days * 864e5).toUTCString();
        document.cookie =
            name +
            '=' +
            encodeURIComponent(value) +
            '; expires=' +
            expires +
            '; path=/';
    }

    if (!showBanner) {
        return null;
    }

    return (
        <div className="fixed bottom-0 w-full bg-white border-t border-gray-200 p-2.5">
            <div className="max-w-screen-xl mx-auto flex items-center justify-between gap-2 sm:gap-4 max-sm:flex-col">
                <p className="text-sm text-gray-600">
                    We use cookies to improve your experience on our site and to
                    show you relevant advertising. To find out more, read our
                    updated{' '}
                    <Link
                        href={composeLink({
                            path: PATHS.PRIVACY_POLICY
                        })}
                        className="underline"
                    >
                        privacy policy
                    </Link>
                    .
                </p>
                <Button theme={ButtonTheme.Outline} onClick={handleAccept}>
                    Got it
                </Button>
            </div>
        </div>
    );
};

export default CookieBanner;
